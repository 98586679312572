export const waitForElement = (selector: string): Promise<Element | null> => {
  return new Promise((resolve) => {
    if (document.querySelector(selector) !== null) {
      resolve(document.querySelector(selector));
      return;
    }

    const observer = new MutationObserver((_mutations) => {
      if (document.querySelector(selector) !== null) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const scrollToElement = (
  element: Element,
  behavior: ScrollBehavior = 'instant' as ScrollBehavior,
): void => {
  element.scrollIntoView({ behavior, block: 'center' });
};

export const HTML_ID = {
  SCHEDULE_FINAL: 'oncall-schedule-final',
  SCHEDULE_ROTATIONS: 'oncall-schedule-rotations',
  SCHEDULE_OVERRIDES_AND_SWAPS: 'oncall-schedule-overrides-and-swaps',
} as const;

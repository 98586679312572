/**
 * Will append a new JS script
 * @param  {string} url of the script
 * @param  {string} id optional id. If specified, the script will be loaded only once for that given id
 */
export const loadJs = function (
  url: string,
  id: string | undefined = undefined,
): void {
  if (id != null) {
    const existingScript = document.getElementById(id);
    if (existingScript != null) {
      return;
    }
  }

  const script = document.createElement('script');
  script.src = url;

  if (id != null) {
    // optional
    script.id = id;
  }

  document.head.appendChild(script);
};

import {
  type Faro,
  initializeFaro,
  LogLevel,
  getWebInstrumentations,
  type BrowserConfig,
  type TransportItem,
  type APIEvent,
} from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import type { AxiosResponse } from 'axios';

import { safeJSONStringify } from './string';

/**
 * Right now we have 2 different config and ways of integrating Faro - one for OnCall and one for Incident.
 * As a part of https://github.com/grafana/irm/issues/163 we will unify them between the two plugins + IRM.
 */

// ONCALL FARO
interface Config {
  faroUrl: string;
  faroAppName: string;
  pluginVersion: string;
  pluginId: string;
}

class BaseFaroHelper {
  public faro?: Faro = undefined;
  private config?: Config = undefined;

  public initializeFaro(config: Config) {
    if (this.faro != null) {
      return undefined;
    }

    this.config = config;

    try {
      const faroOptions: BrowserConfig = {
        url: this.config?.faroUrl,
        isolate: true,
        instrumentations: [
          ...getWebInstrumentations({
            captureConsoleDisabledLevels: [LogLevel.TRACE, LogLevel.ERROR],
          }),
        ],
        app: {
          name: this.config.faroAppName,
          version: this.config.pluginVersion,
        },
        sessionTracking: {
          persistent: true,
        },
        beforeSend: (event: TransportItem<APIEvent>) => {
          if (
            this.config?.pluginId != null &&
            (event.meta.page?.url ?? '').includes(this.config.pluginId) != null
          ) {
            return event;
          }
          return null;
        },
      };

      this.faro = initializeFaro(faroOptions);

      this.faro.api.pushLog([
        `Faro was initialized for ${this.config.pluginId}`,
      ]);
    } catch (ex) {
      console.error(ex);
    }

    return this.faro;
  }

  public pushReactError = (error: Error) => {
    this.faro?.api.pushError(error, { context: { type: 'react' } });
  };

  public pushNetworkRequestEvent = (config: {
    method: string;
    url: string;
    body: string;
  }) => {
    this.faro?.api.pushEvent('Request sent', config);
  };

  public pushFetchNetworkResponseEvent = ({
    name,
    res,
    method,
  }: {
    name: string;
    res: Response;
    method: string;
  }) => {
    this.faro?.api.pushEvent(name, {
      method,
      url: res.url,
      status: `${res.status}`,
      statusText: `${res.statusText}`,
    });
  };

  public pushFetchNetworkError = ({
    res,
    responseData,
    method,
  }: {
    res: Response;
    responseData: unknown;
    method: string;
  }) => {
    this.faro?.api.pushError(new Error(`Network error: ${res.status}`), {
      context: {
        method,
        type: 'network',
        url: res.url,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        data: `${safeJSONStringify(responseData)}`,
        status: `${res.status}`,
        statusText: `${res.statusText}`,
        timestamp: new Date().toUTCString(),
      },
    });
  };

  public pushAxiosNetworkResponseEvent = ({
    name,
    res,
  }: {
    name: string;
    res?: AxiosResponse;
  }) => {
    this.faro?.api.pushEvent(name, {
      url: res?.config?.url ?? '',
      status: `${res?.status}`,
      statusText: `${res?.statusText}`,
      method: res?.config?.method?.toUpperCase() ?? '',
    });
  };

  public pushAxiosNetworkError = (res?: AxiosResponse) => {
    this.faro?.api.pushError(new Error(`Network error: ${res?.status}`), {
      context: {
        url: res?.config?.url ?? '',
        type: 'network',
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        data: `${safeJSONStringify(res?.data)}`,
        status: `${res?.status}`,
        statusText: `${res?.statusText}`,
        timestamp: new Date().toUTCString(),
      },
    });
  };
}

export const FaroHelper = new BaseFaroHelper();

// INCIDENT FARO

// This regex matches URLs that do not contain
// - /a/grafana-incident-app
// - /api/plugins/grafana-incident-app
const IGNORE_URLS = [
  /^((?!\/{0,1}a\/grafana-incident-app|\/api\/plugins\/grafana-incident-app\\).)*$/,
];

// const _FARO_DEV_URL =
//   'https://faro-collector-prod-us-central-0.grafana.net/collect/f1ccb4e97b9633b7a32b9975418fd786';
const FARO_OPS_URL =
  'https://faro-collector-prod-us-central-0.grafana.net/collect/3f76aee8b5f0b310cd4e3e0c71e06ede';
const FARO_PROD_URL =
  'https://faro-collector-prod-us-central-0.grafana.net/collect/00a5e1de7a0db7a18f817503d8891955';

export function integrateFaro({
  grafanaStack,
  pluginVersion,
}: {
  grafanaStack: string | null;
  pluginVersion: string;
}): Faro | null {
  const faroSettings = getFaroSettings(grafanaStack);
  if (faroSettings?.appName != null && faroSettings.url != null) {
    return initializeFaro({
      url: faroSettings?.url,
      isolate: true,
      app: {
        name: faroSettings?.appName,
        version: pluginVersion,
      },
      instrumentations: [
        ...getWebInstrumentations({
          captureConsole: true,
          captureConsoleDisabledLevels: [LogLevel.ERROR],
        }),
        new TracingInstrumentation({
          instrumentations: [
            new DocumentLoadInstrumentation(),
            new FetchInstrumentation({ ignoreUrls: IGNORE_URLS }),
            new XMLHttpRequestInstrumentation({}),
            new UserInteractionInstrumentation(),
          ],
        }),
      ],
    });
  }
  return null;
}

function getFaroSettings(
  grafanaStack: string | null,
): { appName: string; url: string } | null {
  switch (grafanaStack) {
    case null:
      // in case the Org is not loaded yet, we don't know the stack
      return null;
    case '10000':
    case 'dev':
      // Dev env has cors enabled
      return null;
    case 'ops':
      return { appName: 'Incidents-ops', url: FARO_OPS_URL };
    case 'default_org':
      // in case of localhost
      return null;
    default:
      // one of many prod instances
      return { appName: 'Incidents-prod', url: FARO_PROD_URL };
  }
}

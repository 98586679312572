import { config } from '@grafana/runtime';

import type { AxiosError } from 'axios';
import { isArray, concat, isPlainObject, flatMap, map, keys } from 'lodash-es';

import { isNetworkError } from '../network';
import { formatBackendError, openErrorNotification } from './notification';

export function showApiError(error: unknown): void {
  if (
    isNetworkError(error) &&
    error.response != null &&
    error.response.status >= 400 &&
    error.response.status < 500
  ) {
    const text = formatBackendError(error.response.data);
    openErrorNotification(text);
  }
  throw error;
}

export function refreshPageError(error: AxiosError): void {
  if (isNetworkError(error) && error.response?.status === 502) {
    const payload = error.response.data as string;
    const text = `Try to refresh the page. ${payload}`;
    openErrorNotification(text);
  }

  throw error;
}

export function throttlingError(response: Response): void {
  if (response.ok) {
    return;
  }
  if (response?.status === 429) {
    // @ts-expect-error unknown type
    const seconds = Number(response?.headers['retry-after']);
    const minutes = Math.floor(seconds / 60);
    const text =
      'Too many requests, please try again in ' +
      (minutes > 0
        ? `${Math.floor(seconds / 60)} minutes.`
        : `${seconds} seconds.`);
    openErrorNotification(text);
  } else {
    // TODO: check if it works ok
    if (response?.statusText === '') {
      openErrorNotification(
        'Grafana OnCall is unable to verify your phone number due to incorrect number or verification service being unavailable.',
      );
    } else {
      openErrorNotification(response?.statusText);
    }
  }
}

export function getPaths(obj?: unknown, parentKey?: string): string[] {
  let result: unknown;
  if (isArray(obj)) {
    let idx = 0;
    result = flatMap(obj, function (item: unknown) {
      return getPaths(item, (parentKey ?? '') + '[' + idx++ + ']');
    });
  } else if (isPlainObject(obj)) {
    result = flatMap(keys(obj), function (key: string) {
      // @ts-expect-error unknown type
      const value = obj[key];
      return map(getPaths(value, key), function (subkey: string) {
        return (
          (parentKey !== undefined && parentKey !== null
            ? parentKey + '.'
            : '') + subkey
        );
      }) as unknown;
    });
  } else {
    result = [];
  }

  return concat(result, parentKey ?? []) as string[];
}

export function pluralize(word: string, count: number): string {
  return count === 1 ? word : `${word}s`;
}

export function isUseProfileExtensionPointEnabled(): boolean {
  const { major, minor } = getGrafanaVersion();
  // @ts-expect-error undefined will yield false which is ok
  const isRequiredGrafanaVersion = major > 10 || (major === 10 && minor >= 3);

  return isRequiredGrafanaVersion;
}

export const isMobile = window?.matchMedia?.('(max-width: 768px)').matches;

export function getGrafanaVersion(): {
  major?: number;
  minor?: number;
  patch?: number;
} {
  const regex = /^([1-9]?[0-9]*)\.([1-9]?[0-9]*)\.([1-9]?[0-9]*)/;
  const match = regex.exec(config.buildInfo.version);

  if (match != null) {
    return {
      major: Number(match[1]),
      minor: Number(match[2]),
      patch: Number(match[3]),
    };
  }

  return {};
}

export const isCurrentGrafanaVersionEqualOrGreaterThan = ({
  minMajor,
  minMinor = 0,
  minPatch = 0,
}: {
  minMajor: number;
  minMinor?: number;
  minPatch?: number;
}): boolean => {
  const { major, minor, patch } = getGrafanaVersion();
  if (major === undefined || minor === undefined || patch === undefined) {
    return false;
  }
  return (
    major > minMajor ||
    (major === minMajor && minor > minMinor) ||
    (major === minMajor && minor === minMinor && patch >= minPatch)
  );
};

export const getIsExternalServiceAccountFeatureAvailable = ():
  | boolean
  | undefined =>
  isCurrentGrafanaVersionEqualOrGreaterThan({ minMajor: 10, minMinor: 3 }) &&
  config.featureToggles.externalServiceAccounts;

import { getPluginId } from './plugin';
import type { JsonObject } from './types';

class GrafanaPluginFetchClient {
  private static instance: GrafanaPluginFetchClient | null = null;
  private readonly baseUrl: string;

  private constructor() {
    this.baseUrl = `${this.getGrafanaSubUrl()}/api/plugins`;
  }

  private getGrafanaSubUrl() {
    if (typeof window === 'undefined') {
      return ''; // skipping in nonbrowser environment
    }
    try {
      return window.grafanaBootData?.settings?.appSubUrl ?? '';
    } catch (err) {
      console.error('Error getting Grafana sub URL: ', err);
      return '';
    }
  }

  public static getInstance(): GrafanaPluginFetchClient {
    if (this.instance === null) {
      this.instance = new GrafanaPluginFetchClient();
    }
    return this.instance;
  }

  private async fetch<T>(path: string, requestConfig: RequestInit = {}) {
    const url = `${this.baseUrl}/${path}`;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('X-Idempotency-Key', `${Date.now()}-${Math.random()}`);

    const response = await fetch(url, {
      ...requestConfig,
      headers,
    });

    if (!response.ok) {
      throw new Error(`Error fetching ${url}: ${response.statusText}`);
    }

    return (await response.json()) as T;
  }

  public async get<T>(path: string) {
    return this.fetch<T>(path);
  }

  public async post<T, B>(path: string, body?: B) {
    return this.fetch<T>(path, {
      method: 'POST',
      body: body != null ? JSON.stringify(body) : undefined,
    });
  }

  public async put<T, B>(path: string, body: B) {
    return this.fetch<T>(path, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  public async delete<T = undefined>(path: string) {
    return this.fetch<T>(path, {
      method: 'DELETE',
    });
  }

  public async patch<T, B>(path: string, body: B) {
    return this.fetch<T>(path, {
      method: 'PATCH',
      body: JSON.stringify(body),
    });
  }

  public async getInstancePlugins<T extends JsonObject>() {
    return this.get<T>('');
  }

  public async statusAppPluginInstallationWithIncident(): Promise<{
    orgID: string;
    isEnabled: boolean;
    isSetup: boolean;
    isHealthy: boolean;
  } | null> {
    return this.get(`${getPluginId()}/resources/status`);
  }

  public async registerAppPluginInstallationWithIncident(): Promise<
    Record<string, unknown>[]
  > {
    return this.post(`${getPluginId()}/resources/register`);
  }
}

export const grafanaPluginFetchClient = GrafanaPluginFetchClient.getInstance();

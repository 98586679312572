import { getPluginId, PluginId } from '@grafana-irm/core';
import { getBackendSrv } from '@grafana/runtime';

import type {
  ApiAuthKeyDTO,
  NewApiKeyResult,
  PaginatedServiceAccounts,
  ServiceAccountDTO,
  TokenDTO,
  UpdateGrafanaPluginSettingsProps,
} from './api.types';

const pluginId = getPluginId();
// @ts-expect-error Missing incident-app key because it's used only by IRM/OnCall
const KEY_NAME = {
  [PluginId.OnCall]: 'OnCall',
  [PluginId.Irm]: 'IRM',
}[pluginId];

// @ts-expect-error Missing incident-app key because it's used only by IRM/OnCall
const SERVICE_ACCOUNT_NAME = {
  [PluginId.OnCall]: 'sa-autogen-OnCall',
  [PluginId.Irm]: 'sa-autogen-IRM',
}[pluginId];

const KEYS_BASE_URL = '/api/auth/keys';
const SERVICE_ACCOUNTS_BASE_URL = '/api/serviceaccounts';
const GRAFANA_PLUGIN_SETTINGS_URL = `/api/plugins/${pluginId}/settings`;

export class GrafanaApiClient {
  private static readonly grafanaBackend = getBackendSrv();

  private static readonly getServiceAccount = async () => {
    const serviceAccounts =
      await this.grafanaBackend.get<PaginatedServiceAccounts>(
        `${SERVICE_ACCOUNTS_BASE_URL}/search?query=${SERVICE_ACCOUNT_NAME}`,
      );
    return serviceAccounts.serviceAccounts.length > 0
      ? serviceAccounts.serviceAccounts[0]
      : null;
  };

  private static readonly getOrCreateServiceAccount = async () => {
    const serviceAccount = await this.getServiceAccount();
    if (serviceAccount != null) {
      return serviceAccount;
    }

    return this.grafanaBackend.post<ServiceAccountDTO>(
      SERVICE_ACCOUNTS_BASE_URL,
      {
        name: SERVICE_ACCOUNT_NAME,
        role: 'Admin',
        isDisabled: false,
      },
    );
  };

  private static readonly getTokenFromServiceAccount = async (
    // @ts-expect-error Missing type
    serviceAccount,
  ) => {
    const tokens = await this.grafanaBackend.get<TokenDTO[]>(
      `${SERVICE_ACCOUNTS_BASE_URL}/${serviceAccount.id}/tokens`,
    );
    return tokens.find(({ name }) => name === KEY_NAME);
  };

  private static readonly getGrafanaToken = async () => {
    const serviceAccount = await this.getServiceAccount();
    if (serviceAccount != null) {
      return this.getTokenFromServiceAccount(serviceAccount);
    }

    const keys = await this.grafanaBackend.get<ApiAuthKeyDTO[]>(KEYS_BASE_URL);
    return keys.find(({ name }) => name === KEY_NAME);
  };

  public static updateGrafanaPluginSettings = async <T, V>(
    data: UpdateGrafanaPluginSettingsProps<T, V>,
    enabled = true,
  ): Promise<unknown> =>
    this.grafanaBackend.post(GRAFANA_PLUGIN_SETTINGS_URL, {
      ...data,
      enabled,
      pinned: true,
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getGrafanaPluginSettings = async <T>(): Promise<any> => // OnCallPluginMetaJSONData
    this.grafanaBackend.get<{ jsonData: T }>(GRAFANA_PLUGIN_SETTINGS_URL);

  public static recreateGrafanaTokenAndSaveInPluginSettings =
    async (): Promise<unknown> => {
      const serviceAccount = await this.getOrCreateServiceAccount();

      const existingToken =
        await this.getTokenFromServiceAccount(serviceAccount);
      if (existingToken != null) {
        await this.grafanaBackend.delete(
          `${SERVICE_ACCOUNTS_BASE_URL}/${serviceAccount.id}/tokens/${existingToken.id}`,
        );
      }

      const existingKey = await this.getGrafanaToken();
      if (existingKey != null) {
        await this.grafanaBackend.delete(`${KEYS_BASE_URL}/${existingKey.id}`);
      }

      const { key: grafanaToken } =
        await this.grafanaBackend.post<NewApiKeyResult>(
          `${SERVICE_ACCOUNTS_BASE_URL}/${serviceAccount.id}/tokens`,
          {
            name: KEY_NAME,
            role: 'Admin',
          },
        );

      return this.updateGrafanaPluginSettings({
        secureJsonData: { grafanaToken },
      });
    };
}

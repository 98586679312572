import { getPluginId } from './core/plugin';

const getGrafanaSubUrl = () => {
  try {
    // @ts-expect-error window field access
    return window.grafanaBootData.settings.appSubUrl ?? '';
  } catch (_err) {
    return '';
  }
};

export const getOnCallApiPath = (subpath = ''): string => {
  // We need to consider the grafanaSubUrl in case Grafana is served from subpath, e.g. http://localhost:3000/grafana
  return `${getGrafanaSubUrl()}/api/plugins/${getPluginId()}/resources${subpath}`;
};

export const GENERIC_ERROR = 'An error has occurred. Please try again';
export const PROCESSING_REQUEST_ERROR =
  'There was an error processing your request. Please try again';

export enum PAGE {
  Integrations = 'integrations',
  Escalations = 'escalation_chains',
  Incidents = 'incidents',
  Webhooks = 'webhooks',
  Schedules = 'schedules',
  Users = 'users',
}

import type { KeyValue } from '@grafana/data';
import { locationService } from '@grafana/runtime';

class BaseLocationHelper {
  public update(params: KeyValue, method: 'replace' | 'push' | 'partial') {
    const queryParams = getQueryParams();

    const sortedExistingParams = sort(queryParams);
    const sortedNewParams = sort(params);

    if (
      toQueryString(sortedExistingParams) !== toQueryString(sortedNewParams)
    ) {
      if (method === 'partial') {
        locationService.partial(params);
      } else {
        locationService[method](toQueryString(sortedNewParams));
      }
    }
  }

  public getQueryParam(paramKey: string) {
    return getQueryParams()?.[paramKey];
  }
}

function toQueryString(queryParams: KeyValue) {
  const urlParams = new URLSearchParams(queryParams);
  for (const [key, value] of Object.entries(queryParams)) {
    if (Array.isArray(value)) {
      urlParams.delete(key);
      value.forEach((v) => {
        urlParams.append(key, v);
      });
    }
  }
  return urlParams.toString();
}

function sort(object: KeyValue) {
  return Object.keys(object)
    .sort()
    .reduce((obj: Record<string, string | string[]>, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
}

export function getQueryParams(): Record<string, string | string[]> {
  const searchParams = new URLSearchParams(window.location.search);
  const result: Record<string, string | string[]> = {};

  for (const [key, value] of searchParams) {
    if (result[key] !== undefined) {
      // key already existing, we're handling an array
      if (!Array.isArray(result[key])) {
        result[key] = new Array(result[key]);
      }

      result[key].push(value);
    } else {
      result[key] = value;
    }
  }

  return result;
}

export const LocationHelper = new BaseLocationHelper();

import { type AttemptContext, retry } from '@lifeomic/attempt';

export const retryFailingPromises = async (
  asyncActions: Array<(ctx?: AttemptContext) => Promise<unknown>>,
  {
    maxAttempts = 3,
    delayInMs = 500,
  }: { maxAttempts?: number; delayInMs?: number } = {},
): Promise<unknown> =>
  maxAttempts === 0
    ? Promise.allSettled(asyncActions)
    : Promise.allSettled(
        asyncActions.map((asyncAction) =>
          retry(asyncAction, {
            maxAttempts,
            delay: delayInMs,
          }),
        ),
      );

export const waitInMs = (ms: number): Promise<unknown> =>
  new Promise((resolve) => setTimeout(resolve, ms));
